<template>
  <div class="outStockManage">
    <el-row>
      <el-button icon="el-icon-upload">导入</el-button>
      <el-button icon="el-icon-download">导出</el-button>
      <el-button type="primary" @click="handleAddoutStockBtnClick"
        >出库登记</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline :model="outStockFormCondition">
        <!-- 出库批次 -->
        <el-form-item
          class="formItemBoxStyle"
          label="出库批次"
          prop="batchCode"
        >
          <el-input
            style="width: 200px"
            v-model="outStockFormCondition.batchCode"
            placeholder="请输入"
            @blur="handleQueryoutStockManageTableData"
          />
        </el-form-item>
        <!-- 出出库类型 -->
        <el-form-item class="formItemBoxStyle" label="出库分类">
          <el-select
            style="width: 200px"
            v-model="outStockFormCondition.operateType"
            @change="handleQueryoutStockManageTableData"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="借用" :value="201"></el-option>
            <el-option label="报失" :value="202"></el-option>
            <el-option label="报损" :value="203"></el-option>
            <el-option label="正常使用" :value="204"></el-option>
            <el-option label="其他" :value="205"></el-option>
          </el-select>
        </el-form-item>
        <!-- 日期 -->
        <el-form-item class="formItemBoxStyle" label="日期">
          <div class="block">
            <el-date-picker
              v-model="queryoutStockManageTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryoutStockManageDateChange"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetoutStockManageTableData"
            >重置</el-button
          >
          <el-button type="primary" @click="handleQueryoutStockManageTableData"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        v-loading="loadData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :data="outStockManageDataList"
      >
        <el-table-column label="出库批次" prop="batchCode"></el-table-column>
        <el-table-column
          label="出库分类"
          prop="operateType"
          :formatter="customFormatter"
          width="150"
        ></el-table-column>
        <el-table-column
          label="原因"
          prop="reason"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          width="150"
          label="仪器耗材数"
          prop="stockOutAmount"
        ></el-table-column>
        <el-table-column
          width="150"
          label="提交时间"
          prop="submitTime"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scoped">
            <span
              class="edit-span"
              @click="handleCheckoutStockManageDetail(scoped.row)"
              >查看</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeoutStockManageTableMsg"
        @current-change="handleCurrentChangeoutStockManageTableMsg"
        :current-page="outStockManagePageInfo.pageIndex"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="outStockManagePageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="outStockManagePageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 出库弹框 -->
    <el-dialog
      title="出库登记"
      :visible.sync="outStockDialogVisible"
      width="1200px"
      top="3%"
    >
      <div class="dialogTitle">
        <span>出库信息</span>
      </div>
      <el-row style="margin-left: 80px; margin-top: 20px">
        <el-form :model="outStockForm" ref="outStockForm" :rules="rules">
          <el-form-item
            class="formItem redStar"
            label="出库批次："
            prop="batchCode"
          >
            <el-input
              style="width: 400px"
              v-model.trim="outStockForm.batchCode"
              placeholder="请输入"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              :disabled="dialogStatus === 2"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="formItem redStar"
            label="出库分类："
            prop="operateType"
          >
            <el-select
              style="width: 400px"
              v-model.trim="outStockForm.operateType"
              placeholder="请选择"
              :disabled="dialogStatus === 2"
            >
              <el-option label="借用" :value="201"></el-option>
              <el-option label="报失" :value="202"></el-option>
              <el-option label="报损" :value="203"></el-option>
              <el-option label="正常使用" :value="204"></el-option>
              <el-option label="其他" :value="205"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="存放位置："
            prop="placeRoomName"
            class="formItem redStar disabledAdd"
          >
            <el-input
              style="width: 400px"
              v-model="placeRoomName"
              placeholder="请选择"
              disabled
            />
            <div
              v-if="dialogStatus === 0"
              style="
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 440px;
                cursor: pointer;
                color: #00f;
                text-decoration: underline;
              "
              @click="handleSelectFunctionRoomBtnClick()"
            >
              添加
            </div>
          </el-form-item>
          <el-form-item
            style="margin-left: 28px"
            class="formItem redStar reason"
            label="原因："
            prop="reason"
          >
            <el-input
              style="width: 400px"
              placeholder="请输入"
              type="textarea"
              :rows="3"
              resize="none"
              v-model.trim="outStockForm.reason"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              :disabled="dialogStatus === 2"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="margin-left: 38px"
            class="formItem"
            label="附件："
          >
            <el-upload
              v-if="dialogStatus === 0"
              class="upload-demo"
              ref="elUploadRef"
              action="https://jsonplaceholder.typicode.com/posts/"
              :before-remove="beforeRemove"
              :http-request="handleFileUpload"
              accept=".rar, .zip, .doc, .docx, .pdf, .jpg"
              :file-list="fileList"
            >
              <el-button size="middle" type="primary">点击上传</el-button>
              <span slot="tip" class="el-upload__tip" style="margin-left: 10px">
                支持扩展名：.rar .zip .doc .docx .pdf .jpg...
              </span>
            </el-upload>
            <div v-else style="margin-left: 70px">
              <div v-for="(item, index) in uploadFileList" :key="index">
                <span
                  @click="handleDownloadFile(item)"
                  style="margin-right: 100px; color: #00f; cursor: pointer"
                  >附件{{ index + 1 }}</span
                >
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-row>
      <div class="dialogTitle">
        <span>仪器耗材列表</span>
      </div>
      <div
        v-if="dialogStatus === 0"
        style="text-align: right; margin-right: 100px"
      >
        <span
          style="cursor: pointer; color: #00f; text-decoration: underline"
          @click="handleAddMaterialBtnClick"
        >
          + 添加仪器耗材</span
        >
      </div>
      <el-row style="margin-top: 10px">
        <el-table
          :data="addedMaterialDataList"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          border
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            label="编号"
            prop="internationCode"
            width="120"
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="materialName"
            width="150"
          ></el-table-column>
          <el-table-column
            label="规格型号功能"
            prop="materialSpecifications"
          ></el-table-column>
          <el-table-column
            width="100"
            label="单位"
            prop="materialUnit"
          ></el-table-column>
          <el-table-column
            label="是否危化品"
            prop="materialHasDanger"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.materialHasDanger ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否消耗品"
            prop="materialHasConsumables"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.materialHasConsumables ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出库数量" width="150">
            <template slot-scope="scoped">
              <el-input
                onkeyup="this.value=this.value.replace(/\s/g,'')"
                v-model.trim="scoped.row.stockNum"
                placeholder="请输入"
                type="number"
                :min="0"
                :disabled="dialogStatus == 2"
              />
            </template>
          </el-table-column>
          <el-table-column width="80" v-if="dialogStatus !== 2" fixed="right">
            <template slot-scope="scoped">
              <i
                class="el-icon-delete"
                style="
                  color: red;
                  font-size: 22px;
                  cursor: pointer;
                  font-weight: 500;
                "
                @click="handleDeleteAddedMaterialBtnClick(scoped.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div
        v-if="dialogStatus === 0"
        style="text-align: right; margin-top: 20px"
      >
        <el-button
          @click="
            () => {
              outStockDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="handleConfirmAddOutStock"
          >确认</el-button
        >
      </div>
      <div
        v-if="dialogStatus === 2"
        style="text-align: right; margin-top: 20px"
      >
        <el-button
          @click="
            () => {
              outStockDialogVisible = false;
            }
          "
          >关闭</el-button
        >
      </div>
    </el-dialog>
    <!-- 添加仪器耗材弹框 -->
    <el-dialog
      :visible.sync="addMaterialDialogVisible"
      title="添加仪器耗材"
      width="900px"
      top="3%"
    >
      <template>
        <div style="display: flex; justify-content: space-between">
          <div class="tag-title">已选择</div>
          <span
            @click="handleRemoveAllAddedSchoolBtnClick"
            style="
              color: red;
              text-decoration: underline;
              cursor: pointer;
              margin-right: 10px;
            "
            >清空所有</span
          >
        </div>

        <div class="tag-content">
          <div
            v-for="item in addMaterialList"
            :key="item.stockCategoryId"
            class="tag-content-item"
          >
            {{ item.materialName }}
            <div
              class="tag-item-x"
              @click="handleRemoveSelectMaterialBtn(item)"
            >
              X
            </div>
          </div>
        </div>
        <div class="tag-title">仪器耗材库</div>
        <el-row>
          <el-input
            placeholder="请输入编号或名称进行查询"
            v-model="materialCodeName"
            style="width: 300px; margin-right: 20px"
            @blur="handleQueryMaterialTableDataBtnClick"
          />
          <el-button @click="handleResetMaterialTableDataBtnClick"
            >重置</el-button
          >
          <el-button
            type="primary"
            @click="handleQueryMaterialTableDataBtnClick"
            >查询</el-button
          >
          <!-- <el-button
            type="primary"
            style="margin-left: 280px"
            v-throttle
            @click="handleBatchAddBtnClick"
            >批量添加</el-button
          > -->
        </el-row>
        <div style="margin-top: 20px">
          <el-table
            border
            :data="materialTableDataList"
            ref="materialTableDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @select="handleSelectOneMaterial"
            @select-all="handleSelectAllMaterial"
            @row-click="materialRowClick"
          >
            <el-table-column
              prop="internationCode"
              label="国标编号"
              center
              width="80px"
            ></el-table-column>
            <el-table-column prop="materialName" label="名称" center>
            </el-table-column>
            <el-table-column
              prop="materialSpecifications"
              label="规格型号功能"
              center
              width="150px"
            ></el-table-column>
            <el-table-column
              prop="materialUnit"
              label="单位"
              center
              width="60px"
            ></el-table-column>
            <el-table-column label="标准数量" center>
              <template slot-scope="scoped">
                {{ scoped.row.materialMinStandardNum }} ~
                {{ scoped.row.materialMaxStandardNum }}
              </template>
            </el-table-column>
            <el-table-column label="库存数量" prop="stockNum">
              <template slot-scope="scope">
                {{ scope.row.stockNum || 0 }}
              </template>
            </el-table-column>
            <el-table-column prop="materialHasDanger" label="是否危化品" center>
              <template slot-scope="scoped">
                {{ scoped.row.materialHasDanger ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="materialHasConsumables"
              label="是否消耗品"
              center
            >
              <template slot-scope="scoped">
                {{ scoped.row.materialHasConsumables ? "是" : "否" }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="操作">
              <template slot-scope="scoped">
                <div
                  v-if="!handleMaterialStatusCheck(scoped.row)"
                  style="
                    color: #00f;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  @click="handleTableAddMaterialBtnClick(scoped.row)"
                >
                  添加
                </div>
                <div v-else style="color: #ccc">已添加</div>
              </template>
            </el-table-column> -->
            <el-table-column type="selection" width="55"> </el-table-column>
          </el-table>
        </div>
        <el-row style="margin-top: 20px">
          <el-pagination
            @size-change="handleSizeChangeMaterialTableDataMsgPage"
            @current-change="handleCurrentChangeMaterialTableDataMsgPage"
            :current-page="materialTableDataMsgPage.pageIndex"
            :page-sizes="[5, 10, 20, 50, 100, 150]"
            :page-size="materialTableDataMsgPage.pageSize"
            layout="->,total,  prev, pager, next,sizes, jumper"
            :total="materialTableDataMsgPage.pageTotal"
          ></el-pagination>
        </el-row>
        <div style="margin-top: 20px; text-align: right">
          <el-button @click="handleCancelAddMaterialBtnClick">取消</el-button>
          <el-button type="primary" @click="handleSaveAddMaterialBtnClick"
            >保存</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 点击选择存放位置弹框 -->
    <el-dialog
      width="900px"
      title="选择存放位置"
      :visible.sync="addFunctionRoomDialogVisible"
    >
      <el-form inline :model="schoolRoomCondition">
        <el-form-item label="编号/名称" prop="sysOrgSchoolRoomCodeName">
          <el-input
            v-model="schoolRoomCondition.sysOrgSchoolRoomCodeName"
            placeholder="请输入"
            style="width: 160px"
            @input="handleQueryAddFunctionRoomTable"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属科目" prop="sysSubjectDicId">
          <el-select
            v-model="schoolRoomCondition.sysSubjectDicId"
            placeholder="请输入"
            style="width: 160px"
            @change="handleQueryAddFunctionRoomTable"
          >
            <el-option label="全部科目" :value="0"></el-option>
            <el-option
              v-for="item in subjectList"
              :key="item.sysDicId"
              :label="item.sysDicName"
              :value="item.sysDicId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetAddFunctionRoomTable">重置</el-button>
          <el-button @click="handleQueryAddFunctionRoomTable" type="primary"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-row>
        <div style="position: relative">
          <el-table
            border
            :data="functionRoomDataList"
            ref="functionRoomDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @row-click="handleRowClickToAddFunctionRoom"
            @select="handleFunctionRoomSelectionChange"
          >
            <el-table-column
              prop="code"
              label="功能室编号"
              center
              :resizable="false"
            ></el-table-column>
            <el-table-column :resizable="false" prop="name" label="名称" center>
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="sysSubjectDicId"
              label="所属科目"
              center
              :formatter="customFormatter"
            >
            </el-table-column>
            <el-table-column
              prop="sysRoomTypeDicName"
              label="功能室类型"
              :resizable="false"
              center
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="address"
              label="所在地址"
              center
            ></el-table-column>
            <el-table-column
              :resizable="false"
              center
              width="50"
              type="selection"
            >
            </el-table-column>
          </el-table>
          <div
            style="
              width: 40px;
              height: 40px;
              position: absolute;
              top: 0;
              right: 0;
              background-color: #dfe6ec;
              z-index: 999;
            "
          ></div>
        </div>
      </el-row>
      <!-- 分页组件 -->
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChangeAddFunctionRoomTableMsg"
          @current-change="handleCurrentChangeAddFunctionRoomTableMsg"
          :current-page="addFunctionRoomTableMsg.pageIndex"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="addFunctionRoomTableMsg.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="addFunctionRoomTableMsg.pageTotal"
        ></el-pagination>
      </el-row>
      <div style="margin-top: 20px; text-align: right">
        <el-button @click="handleCancelAddFunctionRoom">取消</el-button>
        <el-button type="primary" @click="handleConfirmAddFunctionRoom"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fileSingle } from "@/api/fileUpload/fileUpload.js";
import { getDicFormInfoApi } from "@/api/standard/dictionaryForm.js";
import { getMaterialAccountInfoApi } from "@/api/materialManage/materialAccount.js";
import {
  getOutStockManageListApi,
  addOutStockManageApi,
  getOutStockMaterialData,
  getOutStockListDetailApi,
} from "@/api/materialManage/outInLib.js";
import { getSchoolRoomPageListApi } from "@/api/mechanism/schoolRoomManage.js";
import { verifyInput, isEmpty, formatDate } from "@/utils/util";
import { getStore } from "@/utils/token.js";
export default {
  name: "outStockManage",
  data() {
    const checkOutStockSession = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g;
      if (reg.test(this.outStockForm.batchCode)) {
        callback(new Error("出库批次只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      activePage: "manage",
      dialogStatus: 0, // 0 新增 1 编辑 2 查看
      outStockFormCondition: {
        batchCode: "",
        operateType: "",
        startTime: "",
        endTime: "",
        sysOrgSchoolId: 0,
      },
      queryoutStockManageTime: "",
      outStockManagePageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      outStockManageDataList: [],
      outStockDialogVisible: false,
      outStockForm: {
        batchCode: "",
        operateType: "",
        money: "",
        reason: "",
        placeRoomName: "",
      },
      rules: {
        batchCode: [
          { required: true, message: "请输入出库批次", trigger: "blur" },
          { validator: checkOutStockSession, trigger: "blur" },
        ],
        reason: [
          { required: true, message: "请输入出库原因", trigger: "blur" },
        ],
        operateType: [
          { required: true, message: "请输入出库分类", trigger: "change" },
        ],
        placeRoomName: [
          { required: true, message: "请选择存放位置", trigger: "change" },
        ],
      },
      fileList: [],
      tempAddMaterialList: [],
      addMaterialList: [],
      addMaterialDialogVisible: false,
      materialTableDataList: [],
      materialTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      addedMaterialDataList: [],
      isSavingMaterialList: false,
      materialSelectionList: [],
      addFunctionRoomDialogVisible: false,
      functionRoomDataList: [],
      addFunctionRoomTableMsg: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
      materialIndex: "",
      subjectList: [],
      materialCodeName: "",
      placeRoomName: "",
      placeRoomId: "",
      uploadFileList: [],

      schoolRoomCondition: {
        status: 0,
        sysOrgSchoolId: 0,
        sysOrgSchoolRoomCodeName: "",
        sysSubjectDicId: 0,
      },

      loadData: true,
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"));
    this.outStockFormCondition.sysOrgSchoolId = this.sysOrgSchoolId;
    this.schoolRoomCondition.sysOrgSchoolId = this.sysOrgSchoolId;
    this.fetchoutStockManageData();
    this.fetchFunctionRoomTableData();
    this.fetchSubjectInfo();
  },
  watch: {
    addMaterialDialogVisible: function (val) {
      if (!val && !this.isSavingMaterialList) {
        this.handleCancelAddMaterialBtnClick();
      } else {
        this.isSavingMaterialList = false;
      }
    },
  },
  methods: {
    // 获取出库管理数据列表
    fetchoutStockManageData() {
      getOutStockManageListApi(
        this.outStockManagePageInfo.pageIndex,
        this.outStockManagePageInfo.pageSize,
        this.outStockFormCondition
      ).then((res) => {
        if (res.success) {
          this.outStockManageDataList = res.data;
          this.outStockManagePageInfo.pageTotal = res.total;
          this.loadData = false;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取仪器耗材列表数据
    fetchMaterialListInfo(materialCodeName = this.materialCodeName) {
      getMaterialAccountInfoApi(
        this.materialTableDataMsgPage.pageIndex,
        this.materialTableDataMsgPage.pageSize,
        {
          materialCodeName,
          storageStatus: 1,
          sysOrgSchoolRoomId: this.placeRoomId,
          sysOrgSchoolId: this.sysOrgSchoolId,
        }
      ).then((res) => {
        // console.log(res, "res");
        if (res.success) {
          this.materialTableDataList = res.data;
          if (
            this.addMaterialList.length > 0 &&
            this.materialTableDataList.length > 0
          ) {
            this.materialTableDataList.forEach((it) => {
              this.addMaterialList.forEach((it2) => {
                if (it.stockCategoryId == it2.stockCategoryId) {
                  this.$nextTick(() => {
                    this.$refs.materialTableDataList.toggleRowSelection(
                      it,
                      true
                    );
                    it.isSelected = true;
                  });
                }
              });
            });
          }
          this.materialTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取科目信息
    fetchSubjectInfo() {
      getDicFormInfoApi(4).then((res) => {
        if (res.success) {
          this.subjectList = res.data;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取添加功能室表格数据
    fetchFunctionRoomTableData() {
      getSchoolRoomPageListApi(
        this.addFunctionRoomTableMsg.pageIndex,
        this.addFunctionRoomTableMsg.pageSize,
        this.schoolRoomCondition
      ).then((res) => {
        if (res.success) {
          this.functionRoomDataList = res.data;
          this.addFunctionRoomTableMsg.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 添加出库
    handleAddoutStockBtnClick() {
      this.outStockForm = {
        batchCode: this.getCodeFn("CK"),
        operateType: "",
        money: "",
        reason: "",
        placeRoomName: "",
      };
      this.placeRoomName = "";
      this.placeRoomId = "";
      this.dialogStatus = 0;
      this.addedMaterialDataList = [];
      this.addMaterialList = [];
      this.tempAddMaterialList = [];
      this.outStockDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.outStockForm.clearValidate();
      });
    },
    // 确认出库登记
    handleConfirmAddOutStock() {
      // if (this.outStockForm.operateType == "") {
      //   this.$message.warning("请选择出库分类");
      //   return;
      // }
      // if (this.placeRoomName === "") {
      //   this.$message.warning("请选择存放位置");
      //   return;
      // }
      this.$refs.outStockForm.validate((valid) => {
        if (valid) {
          if (this.addedMaterialDataList.length == 0) {
            this.$message.error("请添加仪器耗材!");
            return;
          }
          let result = false;
          this.addedMaterialDataList.forEach((item) => {
            if (!item.stockNum) {
              result = true;
            }
          });
          if (result) {
            this.$message.error("请完成仪器耗材表格!");
            return;
          }
          if (this.dialogStatus === 0) {
            // 这里新增
            let timer = new Date();
            let submitTime = timer.getTime();
            let stockOutDetailList = this.addedMaterialDataList.map((item) => {
              return {
                internationCode: item.internationCode,
                sysExpConsumeMaterialId: item.sysExpConsumeMaterialId,
                stockCategoryId: item.stockCategoryId,
                stockNum: +item.stockNum,
              };
            });
            let addParams = {
              batchCode: this.outStockForm.batchCode,
              fileUrl: this.fileList.map((item) => item.url),
              operateStatus: 1,
              operateType: this.outStockForm.operateType,
              reason: this.outStockForm.reason,
              stockOutDetailList,
              submitTime,
              sysOrgSchoolRoomId: this.placeRoomId,
              sysOrgSchoolId: this.sysOrgSchoolId,
            };
            addOutStockManageApi(addParams).then((res) => {
              if (res.success) {
                this.$message.success("登记成功!");
                this.fetchoutStockManageData();
                this.outStockDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    //  查看出库管理信息
    handleCheckoutStockManageDetail(row) {
      this.outStockDialogVisible = true;
      getOutStockListDetailApi(row.stockOutId).then((res) => {
        if (res.success) {
          const { data } = res;
          this.outStockForm = {
            batchCode: data.batchCode,
            operateType: data.operateType,
            money: data.purchaseMoney,
            reason: data.reason,
          };
          this.uploadFileList = data.fileUrl;
          this.placeRoomName = data.sysOrgSchoolRoomName;
          this.outStockForm.placeRoomName = data.sysOrgSchoolRoomName;
          //this.addedMaterialDataList = data.stockOutDetailList;
          this.dialogStatus = 2;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
      getOutStockMaterialData(row.stockOutId).then((res) => {
        if (res.success) {
          // console.log(res);
          this.addedMaterialDataList = res.data;
          this.$forceUpdate();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    //  编辑出库管理信息
    // handleEditoutStockManageDetail(row) {},
    //  删除出库管理信息
    // handleDeleteoutStockManageDetail(row) {},
    //  查询出库管理数据
    handleQueryoutStockManageTableData() {
      this.outStockManagePageInfo.pageIndex = 1;
      this.outStockManagePageInfo.pageSize = 10;
      this.fetchoutStockManageData();
    },
    // 重置出库管理数据
    handleResetoutStockManageTableData() {
      this.outStockFormCondition = {
        batchCode: "",
        operateType: "",
        startTime: "",
        endTime: "",
        sysOrgSchoolId: this.sysOrgSchoolId,
      };
      this.queryoutStockManageTime = "";
      this.handleQueryoutStockManageTableData();
    },
    handleSizeChangeoutStockManageTableMsg(val) {
      this.outStockManagePageInfo.pageIndex = 1;
      this.outStockManagePageInfo.pageSize = val;
      this.fetchoutStockManageData();
    },
    handleCurrentChangeoutStockManageTableMsg(val) {
      this.outStockManagePageInfo.pageIndex = val;
      this.fetchoutStockManageData();
    },
    // 出库管理日期选择
    queryoutStockManageDateChange(value) {
      if (value.length > 0) {
        this.outStockFormCondition.startTime = Date.parse(value[0]);
        this.outStockFormCondition.endTime = Date.parse(value[1]);
      }
      this.handleQueryoutStockManageTableData();
    },
    // 查看耗材详情
    handleCheckMaterialStockInfo() {},
    // 弹框移除耗材
    handleDeleteAddedMaterialBtnClick(row) {
      for (let i = 0; i < this.addedMaterialDataList.length; i++) {
        if (
          this.addedMaterialDataList[i].internationCode === row.internationCode
        ) {
          this.addedMaterialDataList.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < this.addMaterialList.length; i++) {
        if (this.addMaterialList[i].internationCode === row.internationCode) {
          this.addMaterialList.splice(i, 1);
          break;
        }
      }
    },
    // 添加耗材
    handleAddMaterialBtnClick() {
      if (!this.placeRoomId) {
        this.$message.warning("请选择存放位置");
        return;
      }
      this.fetchMaterialListInfo();
      // 保存数据
      this.tempAddMaterialList = [];
      for (let i = 0; i < this.addMaterialList.length; i++) {
        let obj = {};
        for (const key in this.addMaterialList[i]) {
          obj[key] = this.addMaterialList[i][key];
        }
        this.tempAddMaterialList.push(obj);
      }
      this.addMaterialDialogVisible = true;
    },
    // 删除所有已添加耗材
    handleRemoveAllAddedSchoolBtnClick() {
      this.addMaterialList = [];
      this.materialTableDataList.forEach((it) => {
        it.isSelected = false;
        this.$refs.materialTableDataList.toggleRowSelection(it, false);
      });
    },
    // 删除已添加耗材按钮
    handleRemoveSelectMaterialBtn(item) {
      this.addMaterialList.forEach((it, i) => {
        if (item.stockCategoryId == it.stockCategoryId) {
          this.addMaterialList.splice(i, 1);
        }
      });
      this.$nextTick(() => {
        this.materialTableDataList.forEach(it=>{
          if(it.stockCategoryId == item.stockCategoryId){
            this.$refs.materialTableDataList.toggleRowSelection(it, false);
            it.isSelected = false
          }
        })
      });
    },
    
    handleSelectOneMaterial(selection, row) {
      this.materialRowClick(row);
    },
    handleSelectAllMaterial(selection) {
      if (selection.length > 0) {
        selection.forEach((it) => {
          let result = true;
          this.addMaterialList.forEach((it2) => {
            if (it.stockCategoryId == it2.stockCategoryId) {
              result = false;
            }
          });
          if (result) {
            it.isSelected = true;
            this.addMaterialList.push(it);
          }
        });
      } else {
        this.materialTableDataList.forEach((it) => {
          this.addMaterialList.forEach((it2) => {
            if (it.stockCategoryId == it2.stockCategoryId) {
              this.$nextTick(() => {
                this.handleRemoveSelectMaterialBtn(it2);
              });
            }
          });
        });
      }
    },
    materialRowClick(row, column, event) {
      if (row.isSelected) {
        this.handleRemoveSelectMaterialBtn(row);
      } else {
        this.$refs.materialTableDataList.toggleRowSelection(row, true);
        row.isSelected = true;
        this.addMaterialList.push(row);
      }
    },
    // 修改仪器耗材分页页容量
    handleSizeChangeMaterialTableDataMsgPage(val) {
      this.materialTableDataMsgPage.pageIndex = 1;
      this.materialTableDataMsgPage.pageSize = val;
      this.fetchMaterialListInfo();
    },
    // 修改仪器耗材分页当前页
    handleCurrentChangeMaterialTableDataMsgPage(val) {
      this.materialTableDataMsgPage.pageIndex = val;
      this.fetchMaterialListInfo();
    },
    // 取消添加仪器耗材
    handleCancelAddMaterialBtnClick() {
      this.addMaterialList = this.tempAddMaterialList;
      this.addMaterialDialogVisible = false;
    },
    // 保存添加仪器耗材
    handleSaveAddMaterialBtnClick() {
      this.isSavingMaterialList = true;
      // 填充表格
      // console.log(this.addMaterialList);
      this.addedMaterialDataList = [
        ...this.addMaterialList.map((item) => ({
          ...item,
          stockNum: 0,
        })),
      ];
      this.addMaterialDialogVisible = false;
    },
    // 调用该方法可以判断仪器耗材表格一栏是显示 添加 还是 已添加
    handleMaterialStatusCheck(row) {
      let result = false;
      if (this.addMaterialList) {
        this.addMaterialList.forEach((item) => {
          if (item.internationCode === row.internationCode) {
            result = true;
          }
        });
      }
      return result;
    },
    // 表格内添加仪器耗材按钮
    handleTableAddMaterialBtnClick(row) {
      this.addMaterialList.push(row);
    },
    // 重置耗材表
    handleResetMaterialTableDataBtnClick() {
      this.materialCodeName = "";
      this.materialTableDataMsgPage.pageIndex = 1;
      this.materialTableDataMsgPage.pageSize = 5;
      this.fetchMaterialListInfo();
    },
    // 查询耗材表
    handleQueryMaterialTableDataBtnClick() {
      this.materialTableDataMsgPage.pageIndex = 1;
      this.materialTableDataMsgPage.pageSize = 5;
      this.fetchMaterialListInfo();
    },
    // 表格选择事件
    handleSelectionChange(selection) {
      // console.log(selection)
      this.materialSelectionList = selection;
    },
    // 批量添加耗材事件
    handleBatchAddBtnClick() {
      if (this.materialSelectionList.length === 0) return;
      for (let i = 0; i < this.materialSelectionList.length; i++) {
        let result = true;
        for (let j = 0; j < this.addMaterialList.length; j++) {
          if (
            this.materialSelectionList[i].internationCode ===
            this.addMaterialList[j].internationCode
          ) {
            result = false;
          }
        }
        if (result) {
          this.addMaterialList.push(this.materialSelectionList[i]);
        }
      }
      // 清空已选的选框
      this.$refs.materialTableDataList.clearSelection();
    },

    // 选择功能室按钮点击
    handleSelectFunctionRoomBtnClick() {
      this.addFunctionRoomDialogVisible = true;
    },
    // 查询功能室
    handleQueryAddFunctionRoomTable() {
      this.addFunctionRoomTableMsg.pageIndex = 1;
      this.addFunctionRoomTableMsg.pageSize = 5;
      this.fetchFunctionRoomTableData();
    },
    // 重置功能室表格
    handleResetAddFunctionRoomTable() {
      this.schoolRoomCondition = {
        status: 0,
        sysOrgSchoolId: this.sysOrgSchoolId,
        sysOrgSchoolRoomCodeName: "",
        sysSubjectDicId: 0,
      };
      this.handleQueryAddFunctionRoomTable();
    },
    // 功能室表格行点击
    handleRowClickToAddFunctionRoom(row, column, event) {
      this.handleFunctionRoomSelectionChange(_, row);
    },
    // 功能室选择事件
    handleFunctionRoomSelectionChange(rows, row) {
      // 清空所有选择
      this.$refs.functionRoomDataList.clearSelection();
      // 再根据selected的值进行目标选框状态确定
      this.functionRoomDataList.forEach((it) => {
        if (it.code == row.code) {
          // console.log(row.selected);
          if (row.selected) {
            it.selected = false;
            this.$refs.functionRoomDataList.toggleRowSelection(row, false);
          } else {
            it.selected = true;
            this.functionRoomSelectedRow = row;
            this.$refs.functionRoomDataList.toggleRowSelection(row, true);
          }
        } else {
          it.selected = false;
        }
      });
    },
    // 功能室表格切换当前行
    handleCurrentChangeAddFunctionRoomTableMsg(val) {
      this.addFunctionRoomTableMsg.pageIndex = val;
      this.addFunctionRoomTableMsg.pageSize = 5;
      this.fetchFunctionRoomTableData();
    },
    // 功能室表格切换页容量
    handleSizeChangeAddFunctionRoomTableMsg(val) {
      this.addFunctionRoomTableMsg.pageSize = val;
      this.fetchFunctionRoomTableData();
    },
    // 取消选择功能室
    handleCancelAddFunctionRoom() {
      this.addFunctionRoomDialogVisible = false;
    },
    // 确认选择功能室
    handleConfirmAddFunctionRoom() {
      this.placeRoomName = this.functionRoomSelectedRow.name;
      this.outStockForm.placeRoomName = this.functionRoomSelectedRow.name;
      this.placeRoomId = this.functionRoomSelectedRow.sysOrgSchoolRoomId;
      this.addFunctionRoomDialogVisible = false;
    },
    handleFileUpload(item) {
      // console.log(item)
      let fd = new FormData();
      fd.append("code", "default");
      fd.append("file", item.file);
      fileSingle(fd).then((res) => {
        if (res.success) {
          this.fileList.push({
            name: item.file.name,
            url: res.data.url,
          });
          this.$message.success("上传成功!");
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].uid == file.uid) {
          this.fileList.splice(i, 1);
          break;
        }
      }
    },
    // 下载附件
    handleDownloadFile(item) {
      window.open(this.$imageAddress(item));
    },
    getCodeFn(keyWord){
      return `${keyWord}${formatDate(new Date(), "yyyyMMddhhmm")}`
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "operateType":
          switch (row.operateType) {
            case 201:
              return "借用";
            case 202:
              return "报失";
            case 203:
              return "报损";
            case 204:
              return "正常使用";
            case 205:
              return "其他";
          }
        case "submitTime":
          return formatDate(new Date(row.submitTime), "yyyy/MM/dd");
        case "sysOrgSchoolRoomId":
          for (let i = 0; i < this.functionRoomDataList.length; i++) {
            if (
              row.sysOrgSchoolRoomId ==
              this.functionRoomDataList[i].sysOrgSchoolRoomId
            ) {
              return this.functionRoomDataList[i].name;
            }
          }
        case "sysSubjectDicId":
          for (let i = 0; i < this.subjectList.length; i++) {
            if (row.sysSubjectDicId == this.subjectList[i].sysDicId) {
              return this.subjectList[i].sysDicName;
            }
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-span {
  color: #00f;
  cursor: pointer;
  text-decoration: underline;
}
.dialogTitle {
  height: 25px;
  border-left: 4px solid #00f;
  line-height: 25px;
  margin-bottom: 10px;
  margin-left: 20px;
  span {
    font-size: 18px;
    margin-left: 20px;
  }
}
::v-deep .redStar {
  .el-form-item__label::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .el-form-item__error {
    margin-left: 100px;
  }
}
::v-deep .redStar.reason {
  .el-form-item__error {
    margin-left: 74px;
  }
}
.redStar1::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.tag-title {
  padding-left: 10px;
  border-left: 1px solid #ccc;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  margin-bottom: 25px;
}
.tag-content {
  margin-bottom: 25px;
  width: 95%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 15px 10px 15px;
}
.tag-content-item {
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  position: relative;
  display: inline-block;
  margin-right: 40px;
  margin-top: 10px;
}
.tag-item-x {
  position: absolute;
  right: -22px;
  top: -1px;
  height: 35px;
  width: 20px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
::v-deep .tabsBox {
  .el-tabs__item {
    font-size: 20px;
    font-weight: 700;
  }
}
::v-deep .upload-demo {
  .el-upload-list--text {
    width: 400px;
    margin-left: 60px;
  }
}
::v-deep .disabledAdd.formItem {
  .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    cursor: auto;
    color: #606266;
  }
}
::v-deep .disabledAdd.formItem {
  &.el-form-item.is-error.is-required {
    .el-input.is-disabled .el-input__inner {
      border-color: #f56c6c;
    }
  }
}
</style>
